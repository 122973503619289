export const allowlistAddresses = [

  ["0x58608a7c9dac7b22da978a8c0a1c49802e822a70",13598],
  ["0xa95e0fb72c9d57cfd2145921746c63b02c753744",12197],
  ["0x1bde49730233aa5f41760a37fc5054a4d9896149",10807],
  ["0xefab400621b8cec4a5df74f536225d0c49690e36",11650],
  ["0x103680ef995e5471cad430c19595c58e9a466248",12985],
  ["0xcb887ee1b82732c69065fe6623d38f11aeca3ad9",13565],
  ["0xf308cc36bd85f135b5e65c8b8ede781b1cbed7bc",13244],
  
]